//
// Crosssell Styles
// ----------------
// div.crosssell-content
//  div.crosssell-wrapper
//   h5.crosssell-block-title
//   ul.quick-add-items
//    li.quick-add-item
//     form
//      div.quick-add-item-wrapper
//       div.quick-add-item-image
//       div.quick-add-item-content
//       div.quick-add-actions
//

.crosssell-content {
	background: map-get($lfi-palette, snow);
	border-radius: rem-calc(10);
	margin: rem-calc(0 16 20);
	padding: rem-calc(24 16 20);

	@include media-breakpoint-up(md) {
		margin: rem-calc(0 40 40);
		padding: rem-calc(32);
	}

	.quick-add-item {
		padding-bottom: 30px;

		@include media-breakpoint-up(md) {
			padding-bottom: 14px;
		}
	}

	.quick-add-action {
		@extend %quick-add-action-icon;
	}
}

.crosssell-wrapper {
	text-align: center;
}

.crosssell-block-title {
	margin-bottom: 0;
}

.crosssell-shop-category {
	@include bold-text();

	align-items: center;
	color: $base-color;
	display: flex;
	justify-content: center;
	margin-top: rem-calc(8);

	&:before {
		display: none;
	}

	&:after {
		height: 18px;
		margin-left: 6px;
		margin-top: 2px;
		width: 18px;
	}
}

// Cross-sell Carousel
.cross-sell-carousel-products-container {
	margin: 0 calc(var(--newleaf-slider-peek-length) * -1);
	padding-bottom: 24px;
	row-gap: 1rem;

	@include media-breakpoint-up(vlarge) {
		padding: var(--newleaf-slider-card-evo-container-padding, 40px 0);
	}

	.newleaf-slider-card-evo {
		margin-top: 0;
		// min-height to minimize content jumps
		min-height: 160px;
		padding-top: 0;

		@include breakpoint($yotpo-desktop-breakpoint) {
			--newleaf-slider-slides-visible: 1;
		}
	}

	.newleaf-circle-button-wrapper {
		margin-top: -2px;

		button {
			background: transparent !important;
			height: 36px;
			-webkit-tap-highlight-color: transparent;
			width: 36px;

			&:before {
				height: 100%;
				margin: 0 !important;
				width: 100%;
			}
		}
	}

	.newleaf-slider-card-evo-title {
		width: 200%;
	}

	.card-evo-content {
		flex-flow: row nowrap;
		gap: 12px;
		height: 100%;
	}

	.card-evo-product-content {
		--card-evo-content-padding: 16px;
	}

	.card-evo-content-container {
		flex: 1 1 50%;
		flex-flow: column nowrap;
		justify-content: space-between;
		min-width: 122px;
	}

	.card-evo-product-information {
		gap: 0;
	}

	.card-evo-product-name {
		@include bold-text;
		color: map-get($lfi-palette, jet);
	}

	.card-evo-product-subtitle {
		color: map-get($lfi-palette, jet);
		margin-bottom: 8px;
	}

	.quick-add-actions {
		margin: 12px 0 4px;
	}

	.cart-evo-image {
		flex: 1 1 50%;
		max-width: 128px;

		img {
			// override newleaf slider style
			height: auto !important;
		}
	}

	.card-evo-actions {
		margin-top: auto;
	}

	.product-image-container {
		background: map-get($lfi-palette, snow);
		border-radius: 6px;
		display: inline-block;
		padding: 4px;
	}

	form {
		width: 100%;
	}
}

// Rule banner styles specific to this context
.crosssell-content .widget-wrapper {
	margin-bottom: rem-calc(30);

	@include media-breakpoint-up(md) {
		margin-bottom: rem-calc(40);
	}

	.rule-banner {
		--rule-banner-bg-color: #fff;
		margin: rem-calc(20) auto 0;
		max-width: rem-calc(400);
		padding: rem-calc(8);
		text-align: center;

		@include media-breakpoint-up(md) {
			margin-top: rem-calc(24);
		}
	}
}

// Rule banner styles specific to this context
.cross-sell-carousel-products-container .widget-wrapper {
	margin-left: var(--newleaf-slider-peek-length);

	.rule-banner {
		display: inline-flex;
		flex-direction: column;
	}

	.sub-message {
		left: rem-calc(20);
		position: relative;
	}
}
