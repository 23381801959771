@import "../../../default/styles/helpers/typography";

// Danner and LaCrosse use different variable names for "bold text"
// This mixin allows use in the default theme and can apply to both themes
@mixin bold-text {
	font-family: $body-font-bold;
	font-weight: normal;
}

// This mixin is an attempt to reduce theme specific overrides
// Danner headers currently are not being text transformed as a default
// Lacrosse headers are BUT we plan to change that with the next Lacrosse
// theme redesign
@mixin theme-header-transformation {
	text-transform: uppercase;
}
