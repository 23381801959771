@use "sass:color";

$top-search-background-color: $white !default;
$top-search-button-color: map-get($lfi-palette, jet) !default;
$top-search-icon-color: map-get($lfi-palette, jet) !default;
$top-search-input-color-active: map-get($lfi-palette, jet) !default;
$top-search-input-color: color.change($top-search-input-color-active, $alpha: $btn-disabled-opacity) !default;
$top-search-suggestion-headline-color: map-get($lfi-palette, metal) !default;
$top-search-sugggestion-link-color: inherit !default;

[id="top-search"] {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: z-index(header) + 2;

	[role="context"] {
		@include media-breakpoint-up(md) {
			@include visually-hidden;
		}
	}

	.block-content {
		align-items: center;
		background: $top-search-background-color;
		display: flex;
		gap: 8px;
		height: $header-container-height;
		justify-content: center;
		padding-left: 24px;
	}

	form {
		display: flex;
		flex: 0 1 486px;
		justify-content: center;
	}

	.search.field {
		width: 100%;
	}

	.control {
		align-items: center;
		display: flex;
		height: 45px;
		position: relative;
		width: 100%;

		&::before {
			@include svg-icon('../icons/icon-search.svg');
			color: $top-search-icon-color;
			font-size: 21px;
			left: 0;
			position: absolute;
		}
	}

	input[id="search"] {
		background: transparent;
		border-color: $top-search-input-color;
		border-radius: 0;
		border-width: 0 0 2px;
		color: $top-search-input-color;

		height: 36px;
		margin: 0;
		padding: 0;
		padding-left: 30px; // icon-search offset
		padding-top: 3px; // ::placeholder vertical-centering

		transition: all 0.3s ease-in-out;
		width: 100%;

		&:hover,
		&:focus {
			border-color: $top-search-input-color-active;
			color: $top-search-input-color-active;
		}
	}

	.actions {
		align-items: center;
		display: flex;
		position: absolute;
		right: 0;
		z-index: z-index(header) + 3;

		button.search {
			background: transparent;
			color: $top-search-button-color;
			cursor: pointer;
			display: block;
			font-size: 30px;
			text-align: right;
			width: 60px;
		}
	}

	.action-close {
		align-items: center;
		color: $top-search-button-color;
		display: flex;
		flex: 0 0 40px;
		height: 100%;
		justify-content: center;
		margin: 0;
		min-width: 40px;

		&:hover {
			color: $top-search-button-color;
		}
	}

	.search-suggestions-container {
		--suggestion-container-min-height: 90vh;

		background: $top-search-background-color;
		display: flex;
		justify-content: center;
		min-height: var(--suggestion-container-min-height);
		padding: 24px 0;

		@include media-breakpoint-up(tablet) {
			--suggestion-container-min-height: unset;
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 20px;
			box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.08);
			margin: 0 auto;
			max-width: 550px;
		}
	}

	.search-suggestions {
		display: flex;
		flex: 0 1 550px;
		flex-flow: column;
		gap: 16px;
		padding: 0 24px;

		@include media-breakpoint-up(md) {
			gap: 24px;
		}

		strong {
			color: $top-search-suggestion-headline-color;
			font-size: 14px;
		}
	}

	.search-suggestion {
		font-size: 18px;
		line-height: 34px;
		margin: 0 16px;

		a {
			color: $top-search-sugggestion-link-color;
		}

		.query-text {
			@include bold-text;
		}
	}
}
