//
// Buttons!
//

// Apply Mixins to Known Selectors
button,
.button,
[type="submit"],
[type="button"] {
	// Default Button Style
	@include button-primary;

	.primary {
		// Just a placeholder. The Primary button is currently the same as the default
	}

	&.primary-light {
		@include button-primary-light;
	}

	&.secondary {
		@include button-secondary;
	}

	&.hollow {
		@include button-type-hollow;
	}

	&.oval {
		@include button-oval;
	}

	&.clear {
		@include button-transparent;
	}

	&.primary-hollow {
		@include button-hollow-primary;
	}

	&.button-with-link-styles {
		@include button-with-link-styles;
	}

	&.button-icon-with-text {
		@include button-icon-with-text;
	}

	&.button-icon-with-text-before {
		@include button-icon-with-text($icon-after: true);
	}

	&.disabled,
	&[disabled] {
		@include button-disabled;
	}

	&.small {
		line-height: 20px;
		padding: 8px 12px;
	}
}

.checkbox-button {
	@include checkbox-button;
}

// ApplePay buttons only appear on Safari and load their own stylesheet
// We just want to make some small adjustments.
#applepay .apple-pay-button {
	border-radius: $default-button-radius;
	cursor: pointer;
	padding: rem-calc(24);
}

// From node_modules/bootstrap/scss/_reboot.scss
[role="button"] {
	cursor: pointer;
}

.button-group {
	.button:not(:last-of-type) {
		margin-right: rem-calc(8);
	}
}

%button-group-responsive {
	display: flex;
	gap: 12px;

	.button {
		flex: 1 1 auto;
	}
}

.button-group-responsive {
	@extend %button-group-responsive;
}

.button-group-stackable {
	@extend %button-group-responsive;
	flex-flow: row wrap;

	.button {
		min-width: 160px;
	}
}

.quick-add-to-cart,
.quick-add-action {
	@include button-hollow-primary;
	min-width: 122px;
	padding: $quick-add-button-padding;
	width: auto;

	i {
		display: none;
		height: 17px;
		width: 17px;

		&:after {
			height: 100%;
			width: 100%;
		}
	}

	&.success,
	&.success:hover,
	&.success:focus {
		// ensure extended styles can't override
		background: map-get($lfi-palette, light-gray) !important;
		border-color: transparent !important;
		color: map-get($lfi-palette, stone) !important;
		cursor: not-allowed;

		@include media-breakpoint-up(md) {
			border-color: map-get($lfi-palette, light-gray) !important;
		}
	}
}

%quick-add-action-icon {
	@include media-breakpoint-down(md) {
		align-items: center;
		background: none;
		border: rem-calc(2) solid $base-primary-color;
		border-radius: rem-calc(50);
		color: $base-primary-color;
		display: flex;
		height: rem-calc(48);
		justify-content: center;
		min-width: unset;
		padding: 0;
		position: relative;
		width: rem-calc(48);

		&:hover {
			background: $base-primary-color;
			color: $white;
		}
	}

	.button-text {
		@include media-breakpoint-down(md) {
			bottom: rem-calc(-28);
			color: $success;
			display: none;
			font-size: rem-calc(14);
			left: 0;
			line-height: rem-calc(18);
			position: absolute;
			right: 0;
			text-transform: none;
		}
	}

	.success {
		display: none;
	}

	&.success {
		.button-text {
			display: inline-block;
		}

		.success {
			display: inline-block;
		}

		.initial {
			display: none;
		}
	}

	i {
		display: inline-block;

		@include media-breakpoint-up(md) {
			display: none !important;
		}
	}
}
